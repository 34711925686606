import { generateProductLiftSSOTokenApi } from "network/api/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiResType } from "types/enum";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "utils/constants/app";

export default function ProductLiftSSO() {
  const navigate = useNavigate();
  const token = process.env.REACT_APP_DUMMY_TOKEN || Cookies.get(STORAGE_KEYS.token);
  const returnToUrl = document.referrer;
  const hostname = window.location.hostname;

  useEffect(() => {
    if (!token) {
      navigate(`/login?redirect_url=https://app.yourgpt.ai/sso/productlift?return_to=${returnToUrl}`);
    }

    async function generateToken() {
      try {
        const res = await generateProductLiftSSOTokenApi({
          token: token || "",
        });

        if (res.type === ApiResType.SUCCESS) {
          window.location.href = `https://roadmap.yourgpt.ai/?sso=${res?.data?.token}`;
        }
      } catch (error) {
        console.log(error);
        window.location.href = `/sso/failed?return_to=${returnToUrl}`;
      }
    }
    generateToken();
  }, [token, navigate, returnToUrl, hostname]);

  return <></>;
}

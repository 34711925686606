import { Input, Typography } from "@mui/joy";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { loginWithSSOApi } from "network/api/auth";
import { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { toast } from "sonner";
import { ApiResType } from "types/enum";
import { logErr } from "utils/helpers";
import OverlayLoader from "./OverlayLoader";

export default function SSOLoginButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleLogin() {
    if (!teamId) {
      toast.error("Please enter a team id");
      return;
    }
    try {
      setLoading(true);
      const res = await loginWithSSOApi({ team_id: teamId });
      if (res?.type === ApiResType.SUCCESS) {
        if (res?.redirect_url) {
          window?.open(res?.redirect_url, "_self");
        }
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      logErr(error);
      setLoading(false);
    }
  }
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={`border border-solid border-gray-300 flex flex-col rounded-md transition-all duration-300 ${isOpen ? "p-2 gap-2" : ""}`}>
        <AnimatePresence>
          {isOpen && (
            <motion.div className="overflow-hidden" initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: "auto" }} exit={{ opacity: 0, height: 0 }} transition={{ duration: 0.4 }}>
              <Input type="text" value={teamId} onChange={(e) => setTeamId(e.target.value)} placeholder="Enter your team id" />
            </motion.div>
          )}
        </AnimatePresence>
        <div className={clsx(itemS, isOpen && "rounded-md border border-solid border-gray-300")} onClick={isOpen ? () => handleLogin() : () => setIsOpen(true)}>
          {loading && <OverlayLoader />}
          <Typography fontWeight={"md"}>{isOpen ? "Sign in" : "Continue with SSO"}</Typography>
        </div>
      </div>
    </ClickAwayListener>
  );
}

const itemS = "overflow-hidden relative flex cursor-pointer items-center gap-3 px-2 py-2 transition-all hover:gap-2 hover:bg-gray-200 justify-center";

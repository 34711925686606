import { Button, Input, Modal, ModalBody, ModalContent, ModalHeader, Spinner } from "@nextui-org/react";
import Avatar from "components/Avatar";
import { useAuth } from "context/AuthContext";
import { getProfilePicSignedurlApi, updateProfileApi } from "network/api/app";
import { useEffect, useState } from "react";
// import toast from "react-hot-toast";
import { PiUploadSimpleBold } from "react-icons/pi";
import { ApiResType } from "types/enum";
import { THEME } from "utils/constants/ui";
import { getUserName } from "utils/helpers";
import { Link, useSearchParams } from "react-router-dom";
import ImageCropper from "components/ImageCropper";
import { DiscordAccountD } from "types/auth";
import { enable2faApi, getConnectedDiscordAccountApi, removeConnectedDiscordAccountApi, sendChangeEmailVerificationApi } from "network/api/auth";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { FiCheck } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { toast } from "sonner";

export default function Profile() {
  const [updating, setUpdating] = useState(false);
  const { token, user, setUser } = useAuth();
  const [updatingImage, setUpdatingImage] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [discordAccData, setDiscordAccData] = useState<DiscordAccountD | null>(null);
  const [loadingDiscordStatus, setLoadingDiscordStatus] = useState<boolean>(false);
  const [disablingDiscord, setDisablingDiscord] = useState<boolean>(false);

  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showDiscordModal, setShowDiscordModal] = useState<boolean>(false);

  const [disabling2FA, setDisabling2FA] = useState<boolean>(false);
  const [showDisable2FAModal, setShowDisable2FAModal] = useState<boolean>(false);

  const [updateEmail, setUpdateEmail] = useState<boolean>(false);
  const [emailToChange, setEmailToChange] = useState<string>("");
  const [changeEmailLoading, setChangeEmailLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "";
  const msg = searchParams.get("message") || "";

  useEffect(() => {
    if (user?.email) {
      setEmailToChange(user?.email);
    }
  }, [user]);

  useEffect(() => {
    if (status === "success") {
      toast.success(msg);
    } else if (status === "error") {
      toast.error(msg);
    }
  }, [status, msg]);

  useEffect(() => {
    const getDiscordAccData = async () => {
      try {
        if (!token) {
          return;
        }
        setLoadingDiscordStatus(true);
        const res = await getConnectedDiscordAccountApi({
          token,
        });
        setDiscordAccData(res?.data);
        setLoadingDiscordStatus(false);
      } catch (err) {
        console.log("err", err);
        setLoadingDiscordStatus(false);
      }
    };
    if (token) {
      getDiscordAccData();
    }
  }, [token]);

  useEffect(() => {
    setFName(user?.first_name || "");
    setLName(user?.last_name || "");
  }, [user]);

  const onImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    console.log(file, "file");

    if (!file) {
      return;
    }
    e.target.value = "";
    setSelectedFile(file);
    setImageUrl(URL.createObjectURL(file));
    setShowCropper(true);
  };

  const onCropComplete = async (croppedImage: Blob) => {
    try {
      setUpdatingImage(true);
      setShowCropper(false);

      if (!selectedFile) {
        throw new Error("No file selected");
      }

      const res = await getProfilePicSignedurlApi({
        token,
        file_name: selectedFile.name,
      });

      if (res?.type === ApiResType.SUCCESS) {
        await fetch(res?.data.url, {
          method: "PUT",
          body: croppedImage,
        });
      }

      const res1 = await updateProfileApi({
        token,
        profile_pic: res?.data.filename,
      });
      setUpdatingImage(false);
      setSelectedFile(null);

      if (res1?.type === ApiResType.SUCCESS) {
        setUser((s: any) => ({ ...s, profile_pic: res1.data.profile_pic }));
        setImageUrl(URL.createObjectURL(croppedImage));
      }

      if (res?.type === ApiResType.ERROR) {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
      setUpdatingImage(false);
      console.log("Err", err);
    }
  };

  const onCropCancel = () => {
    setShowCropper(false);
    setImageUrl("");
    setSelectedFile(null);
  };

  const onUpdate = async (e: any) => {
    e.preventDefault();
    try {
      if (!fName.trim()) {
        toast.error("First name cannot be empty");
        return;
      }

      setUpdating(true);
      const res = await updateProfileApi({
        token,
        first_name: fName.trim(),
        last_name: lName.trim(),
      });
      setUpdating(false);
      if (res?.type === ApiResType.SUCCESS) {
        setUser((s: any) => ({
          ...s,
          first_name: res?.data?.first_name,
          last_name: res?.data?.last_name,
        }));
        toast.success(res?.message || "Udated successfully");
      }
    } catch (err) {
      setUpdating(false);
      console.log("Err", err);
    }
  };

  const img = imageUrl || user?.profile_pic;

  async function disconnectDiscord() {
    if (!token) {
      return;
    }
    try {
      setDisablingDiscord(true);
      const res = await removeConnectedDiscordAccountApi({
        token,
      });
      if (res?.type === ApiResType.SUCCESS) {
        setDiscordAccData(null);
        setShowDiscordModal(false);
        toast.success("Discord disconnected successfully");
      }
    } catch (err) {
      console.log("Err", err);
    }
    setDisablingDiscord(false);
  }

  async function onDisable2FA() {
    try {
      setDisabling2FA(true);
      const res = await enable2faApi({
        token,
        enable: "0",
      });

      if (res?.type === ApiResType.SUCCESS) {
        setUser((s: any) => ({ ...s, "2fa_enabled": "0" }));
        setShowDisable2FAModal(false);
        toast.success("2FA disabled successfully");
      }

      if (res?.type === ApiResType.ERROR) {
        toast.error(res?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Err", error);
      toast.error("Something went wrong");
    } finally {
      setDisabling2FA(false);
    }
  }

  async function onUpdateEmail() {
    if (!emailToChange.includes("@")) {
      toast.error("Please enter a valid email");
      return;
    }
    if (emailToChange === user?.email) {
      toast.error("Please enter a different email");
      return;
    }

    if (!emailToChange.trim()) {
      toast.error("Please enter an email");
      return;
    }

    try {
      setChangeEmailLoading(true);
      const res = await sendChangeEmailVerificationApi({
        token,
        email: emailToChange,
      });

      if (res?.type === ApiResType.SUCCESS) {
        toast.success("To finish changing your email, please check your new email and proceed to verify it.", { duration: 5000 });
        setUpdateEmail(false);
      }
      if (res?.type === ApiResType.ERROR) {
        toast.error(res?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Err", error);
      toast.error("Something went wrong");
    } finally {
      setChangeEmailLoading(false);
      setEmailToChange(user?.email || "");
    }
  }

  return (
    <div>
      <div className="flex flex-col">
        <div style={{ minHeight: `calc(100vh - ${THEME.navbarHeight}px)` }} className="flex flex-col justify-center 2xl:justify-start pb-10  flex-1 gap-5 w-full max-w-lg mx-auto pt-10 px-10 mt-[180px] md:mt-[60px]">
          <div
            className={`bg-gray-100 ring-1 ring-gray-200 hover:ring-primary-100 h-[120px] mx-auto w-fit group cursor-pointer relative aspect-square overflow-hidden rounded-full flex justify-center items-center ${
              updatingImage && "pointer-events-none"
            }`}
          >
            {img ? <img alt="User profile" src={img} className="object-cover h-full w-full" /> : <Avatar name={getUserName(user)} classNames="h-full text-[34px] rounded-full" />}

            <div className="bg-black/60 group-hover:opacity-[1] opacity-[0] transition-all h-[100%] flex justify-center items-center absolute bottom-0 w-full left-0 z-[3]">
              <PiUploadSimpleBold size={24} className="text-white" />
            </div>

            <input onChange={onImage} type="file" accept="image/png, image/jpeg" className="absolute w-full h-full z-[4]  left-0 top-0 cursor-pointer opacity-0" />

            {updatingImage && (
              <div className="flex  justify-center items-center bg-black/70 absolute z-[7] top-0 left-0 h-full w-full">
                <Spinner color="white" />
              </div>
            )}
          </div>

          <div className="mx-auto text-center">
            <p className="text-2xl font-bold capitalize">{user?.first_name}</p>
            {!updateEmail ? (
              <div className="flex items-center gap-2">
                <p className="text-gray-500">{user?.email}</p>
                <HiOutlinePencilSquare size={16} className="cursor-pointer opacity-60 hover:opacity-100 transition-all" onClick={() => setUpdateEmail(true)} />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onUpdateEmail();
                    }
                    if (e.key === "Escape") {
                      setUpdateEmail(false);
                      setEmailToChange(user?.email || "");
                    }
                  }}
                  className="w-72 border border-foreground-300/60 rounded-md text-sm p-2 px-3"
                  value={emailToChange}
                  onChange={(e) => setEmailToChange(e.target.value)}
                />
                <button className="px-4 py-1 w-6 text-xs rounded-md" disabled={changeEmailLoading}>
                  {changeEmailLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <div className="flex items-center justify-center gap-2">
                      <span onClick={onUpdateEmail} className="cursor-pointer text-primary-500">
                        <FiCheck size={18} />
                      </span>
                      <span
                        onClick={() => {
                          setUpdateEmail(false);
                          setEmailToChange(user?.email || "");
                        }}
                        className="cursor-pointer text-red-500"
                      >
                        <RxCross2 size={18} />
                      </span>
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>

          <form className="flex flex-col gap-6" onSubmit={onUpdate}>
            <Input size="lg" required isRequired value={fName} onChange={(e) => setFName(e.target.value)} type="text" label="First Name" placeholder="Your first name" labelPlacement="outside" />
            <Input size="lg" value={lName} onChange={(e) => setLName(e.target.value)} type="text" label="Last Name" placeholder="Your last name" labelPlacement="outside" />

            <Button size="lg" color={"primary"} type="submit" isLoading={updating}>
              Save
            </Button>
          </form>

          <div className="flex items-center gap-2">
            <div className="h-[1px] bg-foreground-300 w-full flex-1"></div>
            <div className="text-sm text-gray-400 shrink-0">More</div>
            <div className="h-[1px] bg-foreground-300 w-full flex-1"></div>
          </div>

          {user?.["2fa_enabled"] === "0" ? (
            <Link to={"/setup-2fa"} className="text-indigo-500 text-sm hover:text-indigo-700 transition-all rounded-xl flex justify-center items-center gap-2">
              Setup 2FA
            </Link>
          ) : (
            <div onClick={() => setShowDisable2FAModal(true)} className="cursor-pointer text-indigo-500 text-sm hover:text-indigo-700 transition-all rounded-xl flex justify-center items-center gap-2">
              Disable 2FA
            </div>
          )}

          {!loadingDiscordStatus ? (
            <>
              {discordAccData?.discord_username ? (
                <div className="flex gap-2 items-center bg-foreground-100 p-3 rounded-xl border border-foreground-300/60">
                  <div className="flex flex-1 items-center gap-2">
                    <div className="bg-indigo-500 text-white p-2 rounded-full">
                      <img src="/images/discord.png" className="h-4" alt="discord" />
                    </div>
                    <div className="flex gap-1 items-center">
                      <div className="font-semibold">{discordAccData?.discord_global_name}</div>
                      <div className="text-xs opacity-70">( {discordAccData?.discord_username} )</div>
                    </div>
                  </div>
                  <div onClick={() => setShowDiscordModal(true)} className="bg-red-500 text-sm cursor-pointer text-center rounded-md px-3 py-1 text-white">
                    Disconnect
                  </div>
                </div>
              ) : (
                <Link to={"/connect-to-discord"} className="text-indigo-500 text-sm hover:text-indigo-700 transition-all rounded-xl flex justify-center items-center gap-2">
                  {/* <img src="/images/discord.png" className="h-4" alt="discord" /> */}
                  Connect Discord
                </Link>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center">
              <Spinner size="sm" />
            </div>
          )}
          <Link className="text-red-400 hover:text-red-600 text-sm text-center" to={"/account-delete-request"}>
            Delete my account
          </Link>
        </div>
      </div>

      <Modal isOpen={showDiscordModal} onClose={() => setShowDiscordModal(false)} size="xl" placement="center">
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Disconnect Discord</ModalHeader>
          <ModalBody>
            <p>Are you sure you want to disconnect your Discord account?</p>
            <Button isLoading={disablingDiscord} className="mb-2" color="danger" onClick={disconnectDiscord}>
              Disconnect
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showCropper} onClose={onCropCancel} size="xl" placement="center">
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Crop Image</ModalHeader>
          <ModalBody>{imageUrl && <ImageCropper image={imageUrl} onCropComplete={onCropComplete} onCancel={onCropCancel} />}</ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showDisable2FAModal} onClose={() => setShowDisable2FAModal(false)} size="xl" placement="center">
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Disable 2FA</ModalHeader>
          <ModalBody>
            <p>Are you sure you want to disable 2FA?</p>
            <Button isLoading={disabling2FA} className="mb-2" color="danger" onClick={onDisable2FA}>
              Disable
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

import { Spinner } from "@nextui-org/react";
import { useAuth } from "context/AuthContext";
import { verifyChangeEmailApi } from "network/api/auth";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ApiResType } from "types/enum";

export default function VerifyEmailChange() {
  const { token, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { hash } = useParams();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  async function verifyEmailChange() {
    if (!token) {
      toast.error("Looks like you're not logged in");
      return;
    }

    if (!hash) {
      toast.error("This link seems to be invalid");
      return;
    }

    try {
      setLoading(true);
      const res = await verifyChangeEmailApi({ token, hash });
      if (res?.type === ApiResType.SUCCESS) {
        setUser((prev: any) => ({ ...prev, email: res?.data?.email }));
        toast.success("Email changed successfully");

        navigate("/profile");
      }

      if (res?.type === ApiResType.ERROR) {
        setError(res?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Err", error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className="flex h-screen w-full items-center justify-center max-w-screen-sm mx-auto">
      <div className="w-full">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-bold">Verify Email Change</p>
            <p className="text-sm text-gray-500">Please verify your email change by clicking the button below.</p>
            <div className="flex items-center gap-4 mt-6">
              <button
                disabled={loading}
                onClick={verifyEmailChange}
                className="px-6 py-2.5 text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 disabled:opacity-50 rounded-lg transition-colors duration-200 flex items-center justify-center min-w-[120px]"
              >
                {loading ? <Spinner size="sm" color="white" /> : "Verify"}
              </button>
              <button onClick={() => navigate("/profile")} className="px-6 py-2.5 text-sm font-medium text-gray-700 hover:text-gray-900 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200">
                Back to Profile
              </button>
            </div>

            <p className={`text-red-500 text-sm mt-2 ${error ? "opacity-100" : "opacity-0"}`}>{error || "🙂"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";

export default function SSOFailed() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="max-w-xl w-full p-8 text-center backdrop-blur-sm bg-white/70 rounded-xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] border border-white/20">
        <div className="mb-5">
          <div className="mx-auto h-14 w-14 text-red-500 p-2 bg-red-500/10 rounded-full flex items-center justify-center">
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
        </div>
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">SSO Login Failed</h1>
        <p className="text-gray-600 mb-8">We couldn't authenticate you using SSO. Please try again or use another login method.</p>
        <button
          onClick={() => navigate("/login")}
          className="w-full bg-blue-600/90 backdrop-blur-sm text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-all duration-200 shadow-lg hover:shadow-xl hover:ring-2 hover:ring-blue-200 ring-offset-1 ring-offset-white flex items-center justify-center gap-2 group"
        >
          <span className="max-w-0 group-hover:max-w-4 transition-all duration-200 overflow-hidden">
            <GoArrowLeft className="w-4 h-4" />
          </span>
          Return to Login
        </button>
      </div>
    </div>
  );
}

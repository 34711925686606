export const triggerDiscordLog = async (message: string) => {
  const webhookURL = "https://discord.com/api/webhooks/1342757516060135466/yHVNGDloet4xoUUl70d-0xLri8X6wbga7qcG6U4X2cJbXPBi2hAvA8NipyzI1eZ4tb8n";
  const project = "🫵🏼 App YourGPT";
  if (process.env.NODE_ENV === "development") return;

  // Function to truncate the stack trace
  const truncateStack = (stack: string, maxLength: number = 1500) => {
    if (!stack) return "No stack trace";
    if (stack.length <= maxLength) return stack;
    return stack.substring(0, maxLength) + "... [truncated]";
  };

  const errorPayload = {
    project: project,
    currentURL: typeof window !== "undefined" ? window.location.href : "Server-side error",
    errorStack: truncateStack(message),
  };

  const discordMessage = {
    content: `🚨 Error in ${project}:\n\`\`\`json\n${JSON.stringify(errorPayload, null, 2)}\n\`\`\``.slice(0, 1950),
    username: project,
    avatar_url: "https://chatbot.yourgpt.ai/images/images/chatbot-small-logo.png",
  };

  try {
    await fetch(webhookURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(discordMessage),
    });
  } catch (error) {
    console.error("Failed to send error to Discord:", error);
  }
};

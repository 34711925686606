import { useRouteError } from "react-router-dom";
import { triggerDiscordLog } from "network/api/log";

export default function RouteError() {
  const error = useRouteError() as Error;
  triggerDiscordLog(`Error: ${error.message}\nStack trace: ${error.stack}`);

  const handleHardRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="flex min-h-screen justify-center items-center bg-gray-100">
      <div className="max-w-lg p-8 flex flex-col items-center">
        <img src="/images/svg/500-error.svg" className="h-[200px] w-auto mb-8 -mr-12" alt="Broken Figure" />

        <h1 className="font-semibold mb-2 text-lg">Oops!</h1>
        <p className="text-gray-600 mb-4 text-sm text-center leading-relaxed">
          There seems to be an issue with the page you're trying to access. If the issue persists, please contact us through{" "}
          <a href="https://yourgpt.ai/" target="_blank" rel="noreferrer" className="text-blue-500 underline">
            live chat
          </a>{" "}
          or email at{" "}
          <a href="mailto:support@yourgpt.ai" className="text-gray-950">
            support@yourgpt.ai
          </a>
        </p>
        <button onClick={handleHardRefresh} className="bg-gray-800 text-gray-100 hover:bg-gray-900 transition-all duration-300 px-4 py-2 rounded-md text-sm font-medium">
          Refresh Page
        </button>
      </div>
    </div>
  );
}

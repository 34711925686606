import { Spinner } from "@nextui-org/react";
import { useAuth } from "context/AuthContext";
import { getUserDetailsApi } from "network/api/app";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function SSOSuccess() {
  const [searchParams] = useSearchParams();
  const { onLoginSuccess } = useAuth();
  const token = searchParams.get("token");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleSSO = async () => {
      try {
        if (!token) {
          throw new Error("No token provided");
        }
        const res = await getUserDetailsApi({ token });

        if (res.type === "RXERROR") {
          throw new Error(res.message);
        }
        if (res.type === "RXSUCCESS") {
          onLoginSuccess({
            data: { ...(res.data || {}), token },
            persist: true,
          });
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "Authentication failed");
      } finally {
        setIsLoading(false);
      }
    };

    handleSSO();
  }, [token]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "red",
        }}
      >
        {error}
      </div>
    );
  }

  return null;
}
